import { graphqlQuery } from "../axios";
import _get from 'lodash/get';

export function getIgBusinessAccounts(org_id, includeParents) {
    return graphqlQuery({
        query: `query getIgBusinessAccounts($org_id: Int! $includeParents: Boolean){
                    Organization(id: $org_id){ id
                        InstagramBusinessAccounts(includeParents: $includeParents){
                            id
                            ig_id
                            organization: public_organization{ id name }
                            page_id
                            username
                            profile_picture_url
                            isProfilePictureUrlExpired
                            name
                            valid
                        }
                    }
        }`, variables: { org_id, includeParents }
    }).then(response => {
        return {
            response: _get(response, 'data.data.Organization.InstagramBusinessAccounts', []),
            error: _get(response, 'data.error', false)
        }
    });
}

export function disconnectIgBusinessAccount(org_id, page_id) {
    return graphqlQuery({
        query: `mutation disconnectIgBusinessAccount($org_id: Int!, $page_id: String!){
            disconnect_instagram_page(org_id: $org_id, page_id: $page_id) {
                successful
                message
            }
        }`, variables: { org_id, page_id }
    }).then(response => {
        return {
            successful: _get(response, 'data.data.disconnect_instagram_page.successful', false),
            message: _get(response, 'data.data.disconnect_instagram_page.message', ""),
            error: _get(response, 'data.error', false)
        }
    });
}