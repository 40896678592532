import { graphqlQuery } from '../axios';
import _ from 'lodash';
import { spotlightBackgroundFragment } from './spotlightBackgrounds';

export async function getOrganization(org_id) {
    const response = await graphqlQuery({
        query: `query em_get_org_organizations($org_id: Int!) { 
            Organization(id: $org_id) {
                permissions
                brandkitSettings:settings(prefix:"brandkit."){
                    key
                    value
                }
                brandKit {
                    logo
                    accent_color
                    background_color
                    hide_poweredbyupshow
                    font_body_color
                    font_body_family
                    font_accent_color
                    font_title_color
                    font_title_family
                    bohBackgrounds {
                        anniversary { ...backgroundFields }
                        birthday { ...backgroundFields }
                        charity { ...backgroundFields }
                        cleaning_policy { ...backgroundFields }
                        employee_bio { ...backgroundFields }
                        shift_notes { ...backgroundFields }
                        payday { ...backgroundFields }
                    }
                }
                accounts (include_childs:true) { id timezone }
                id leaf billing root name hasTriviaGameId
                features {feature enabled}
                child_organizations { id name children_count } 
                parent_organizations { id name }
                depth
                vertical { key label }
                experience
            }
         }
         
         ${spotlightBackgroundFragment}
         
         `, variables: {
            org_id
        }
    });
    const accounts = response.data.data.Organization.accounts;
    const timezones = _(accounts)
                        .groupBy('timezone')
                        .map((items, timezone) => ({ timezone, count: items.length }))
                        .orderBy(['count'], ['desc'])
                        .value();
    return {
        organization: response.data.data.Organization,
        timezones
    };
}

export async function setDefaultMix (organizationId, script_id, script_name) {
    const response = await graphqlQuery({
        query: `mutation setDefaultMix($organizationId: Int!, $script_id: Int!, $script_name: String!){ 
            setDefaultMix(org_id: $organizationId, script_id: $script_id, script_name: $script_name){
                successful
                status
                message
            }
        }`,
        variables: { organizationId, script_id, script_name },
    });

    return _.get(response, 'data.data.setDefaultMix', []);
}

export async function setSmartMix(organizationId, script_id, override = false) {
    const response = await graphqlQuery({
        query: `mutation setSmartMix($organizationId: Int!, $script_id: Int!, $override: Boolean!){
            orgMutation(organization_id: $organizationId) {
                setSmartMix(script_id: $script_id, override: $override){
                    successful
                    status
                    message
                }
            }
        }`,
        variables: {organizationId, script_id, override}
    });

    return _.get(response, 'data.data.orgMutation.setSmartMix', null);
}

export async function setMixLocking(organizationId, script_id, locking) {
    const response = await graphqlQuery({
        query: `mutation setMixLocking($organizationId: Int!, $script_id: Int!, $locking: Boolean!){ 
            orgMutation(organization_id: $organizationId) {
                setMixLock(script_id: $script_id, lock: $locking){
                    status
                    successful
                    message
                }
            }
        }`,
        variables: { organizationId, script_id, locking }
    });

    return _.get(response, 'data.data.orgMutation.setMixLock', null);
}