import {all} from "redux-saga/effects";

import entities from "./entities/sagas";
import session from "./session/sagas";
import facebook from "./facebook/sagas";


export default function* rootSaga() {
    yield all([
        entities(),
        session(),
        facebook(),
    ]);
};