const allFeatures = {
    SOCIAL: 'social',
    PLUTO: 'plutotv',
    UP_NOW: 'upshow_now',
    MEDIA_CHANNEL_PREMIUM: 'jukin',
    MEDIA_CHANNEL_NON_PREMIUM: 'media_channel_non_premium',
    MEDIA_CHANNEL_NHL: 'media_channel_nhl',
    MEDIA_CHANNEL_VOD: 'media_channel_vod',
    SPOTLIGHT: 'spotlights',
    ANALYTICS: 'analytics',
    DEVICES: 'device_manager',
    GAMES: 'upshow_games',
    TOUCHTUNES: 'touchtunes',
    MIXES: 'upshow_mixes',
    CREATE_SCHEDULES_MIXES: 'upshow_mixes_create_schedules',
    MIXES_SET_DEFAULT: 'upshow_mixes_set_default',
    MIXES_SET_LOCKING: 'upshow_mixes_set_locking',
    MIXES_EDITOR: 'upshow_mixes_editor',
    GOOGLE_ADMIN_DATA: 'google_admin_data',
    MIXES_GRANULARITY: 'mixes_granularity',
    SPOTLIGHT_TAGS: 'spotlight_tags',
    DISPLAY_RULES: 'display_rules',
    SPOTLIGHT_BOH_TRAINING_COURSE: 'spotlight_boh_training_course',
    SPOTLIGHT_BOH_CUSTOMER_COMMENTS: 'spotlight_boh_customer_comments',
    SPOTLIGHT_BOH_LOCATION_AVG_TICKET: 'spotlight_boh_avg_tickets',
    SPOTLIGHT_BOH_AVG_CHECK_SIZE: 'spotlight_boh_avg_check_size',
    SPOTLIGHT_BOH_SHIFT_LOG: 'spotlight_boh_shift_log',
    SPOTLIGHT_BOH_NPS: 'spotlight_boh_nps',
    SPOTLIGHT_BOH_CAR_COUNT: 'spotlight_boh_car_count',
    SPOTLIGHT_BOH_DRIVE_THRU_SOS: 'spotlight_boh_drive_thru_sos',
    SPOTLIGHT_BOH_STORE_SPEED_OF_SERVICE: 'spotlight_boh_store_speed_of_service',
    SPOTLIGHT_BOH_HUDDLE_BOARD: 'spotlight_boh_huddle_board',
    SPOTLIGHT_BOH_PRODUCT_SALES_LEADERBOARD: 'spotlight_product_sales_leaderboard',
    SPOTLIGHT_BOH_THERMOMETER_SALES: 'spotlight_boh_thermometer_sales',
    SPOTLIGHT_BOH_SAME_STORE_SALES: 'spotlight_boh_same_store_sales',
    SPOTLIGHT_BOH_AVG_GUEST_SPEND: 'spotlight_boh_avg_guest_spend',
    SPOTLIGHT_BOH_PRODUCT_SALES_BY_STORE: 'spotlight_boh_product_sales_by_store',
    SPOTLIGHT_BOH_EMPLOYEE_LOYALTY_LEADERBOARD: 'spotlight_boh_employee_loyalty_leaderboard',
    SPOTLIGHT_BOH_PERCENTAGE_TO_GOAL: 'spotlight_boh_percentage_to_goal',
    SPOTLIGHT_BOH_BIO: 'spotlight_boh_bio',
    SPOTLIGHT_BOH_EMPLOYEE_HIGHLIGHT: 'spotlight_boh_employee_highlight',
    SPOTLIGHT_BLAZIN_BY_STORE: 'spotlight_blazin_by_store',
    SPOTLIGHT_BOH_BLAZIN_ATTACHMENT: 'spotlight_blazin_attachment',
    SPOTLIGHT_BOH_TRAINING_BY_STORE: 'spotlight_training_by_store',
    SPOTLIGHT_BOH_BIRTHDAY: 'spotlight_boh_birthday',
    BOH_EMPLOYEE_BULK: 'boh_employee_bulk',
    SPOTLIGHT_BOH_ANNIVERSARY: 'spotlight_boh_anniversary',
    SPOTLIGHT_BOH_HUDDLE_NOTES: 'spotlight_boh_huddle_notes',
    SPOTLIGHT_BOH_BIRTHDAY_SINGLE_EMPLOYEE: 'spotlight_boh_birthday_single_employee',
    SPOTLIGHT_BOH_ANNIVERSARY_SINGLE_EMPLOYEE: 'spotlight_boh_anniversary_single_employee',
    BOH_SPOTLIGHTS: 'boh_spotlights',
    BOH_CONTESTS: 'boh_contests',
    LIVE_EVENTS: 'live_events',
    SPORTSBOOK: 'sportsbook',
    SPORTSBOOK_SCHEDULE: 'sportsbook_schedule',
    CAMPAIGNS: 'campaigns',
    CREATE_FROM_SIDEBAR: 'create_from_sidebar',
    OWN_CANVA_LOGIN: 'own_canva_login',
    CAMPAIGN_CANVAS_DEFAULT_CREATOR: 'campaign_canvas_default_creator',
    SPOTLIGHT_APPROVAL: 'spotlight_approval',
    SPOTLIGHT_BOH_LOYALTY_GAUGE: 'spotlight_boh_loyalty_gauge',
    BOH_LOYALTY_REPORT: 'boh_loyalty_report',
    PREMIUM_MUSIC: 'premium_music',
    MULTIPLE_DESTINATIONS_CAMPAIGN: 'multiple_destinations_campaign',
    BILLING: 'billing',
    SPOTLIGHT_BOH_SHOUTOUT: 'spotlight_boh_shoutout',
    AUDIO_ANNOUNCEMENT: 'audio_announcement',
    SPOTLIGHT_BOH_PRODUCT_HIGHLIGHT: 'spotlight_boh_product_highlight',
    SPOTLIGHT_BOH_PAYDAY: 'spotlight_boh_payday',
    ORGANIZATION_LABELS: 'organization_labels',
    SPOTLIGHT_LAYOUT_MULTI: 'multiple_graphic_spotlight',
    DR_TIME_BASED: 'dr_time_based',
    SMART_PLAYLISTS: 'smart_playlists',
    PEACOCK: 'peacock',
    BLOCK_CONTENT: 'block_content',
    SUNDAY_TICKET: 'sunday_ticket',
    LIVE_EVENTS_MULTIPLE_DEVICES:'live_events_multiple_devices'
};

const functionalitySelector = {
    SOCIAL: [allFeatures.SOCIAL],
    PLUTO: [allFeatures.PLUTO],
    UP_NOW: [allFeatures.UP_NOW],
    MEDIA_CHANNEL_PREMIUM: [allFeatures.MEDIA_CHANNEL_PREMIUM],
    MEDIA_CHANNEL_NON_PREMIUM: [allFeatures.MEDIA_CHANNEL_NON_PREMIUM],
    MEDIA_CHANNEL_NHL: [allFeatures.MEDIA_CHANNEL_NHL],
    MEDIA_CHANNEL_VOD: [allFeatures.MEDIA_CHANNEL_VOD],
    MEDIA_CHANNEL: [allFeatures.MEDIA_CHANNEL_PREMIUM, allFeatures.MEDIA_CHANNEL_NON_PREMIUM, allFeatures.MEDIA_CHANNEL_NHL, allFeatures.MEDIA_CHANNEL_VOD],
    ENTERTAINMENT: [allFeatures.PLUTO, allFeatures.UP_NOW, allFeatures.MEDIA_CHANNEL_PREMIUM, allFeatures.GAMES, allFeatures.MEDIA_CHANNEL_NON_PREMIUM, allFeatures.MEDIA_CHANNEL_NHL, allFeatures.MEDIA_CHANNEL_VOD, allFeatures.LIVE_EVENTS, allFeatures.SPORTSBOOK],
    ANALYTICS: [allFeatures.ANALYTICS],
    SPOTLIGHT: [allFeatures.SPOTLIGHT],
    DEVICES: [allFeatures.DEVICES],
    GAMES: [allFeatures.GAMES],
    TOUCHTUNES: [allFeatures.TOUCHTUNES],
    MIXES: [allFeatures.MIXES, allFeatures.MIXES_EDITOR],
    CREATE_SCHEDULES_MIXES: [allFeatures.CREATE_SCHEDULES_MIXES],
    MIXES_SET_DEFAULT: [allFeatures.MIXES_SET_DEFAULT],
    MIXES_SET_LOCKING: [allFeatures.MIXES_SET_LOCKING],
    MIXES_EDITOR: [allFeatures.MIXES_EDITOR],
    GOOGLE_ADMIN_DATA: [allFeatures.GOOGLE_ADMIN_DATA],
    MIXES_GRANULARITY: [allFeatures.MIXES_GRANULARITY],
    SPOTLIGHT_TAGS: [allFeatures.SPOTLIGHT_TAGS],
    DISPLAY_RULES: [allFeatures.DISPLAY_RULES],
    SPOTLIGHT_BOH_TRAINING_COURSE: [allFeatures.SPOTLIGHT_BOH_TRAINING_COURSE],
    SPOTLIGHT_BOH_CUSTOMER_COMMENTS: [allFeatures.SPOTLIGHT_BOH_CUSTOMER_COMMENTS],
    SPOTLIGHT_BOH_LOCATION_AVG_TICKET: [allFeatures.SPOTLIGHT_BOH_LOCATION_AVG_TICKET],
    SPOTLIGHT_BOH_AVG_CHECK_SIZE: [allFeatures.SPOTLIGHT_BOH_AVG_CHECK_SIZE],
    SPOTLIGHT_BOH_SHIFT_LOG: [allFeatures.SPOTLIGHT_BOH_SHIFT_LOG],
    SPOTLIGHT_BOH_NPS: [allFeatures.SPOTLIGHT_BOH_NPS],
    SPOTLIGHT_BOH_CAR_COUNT: [allFeatures.SPOTLIGHT_BOH_CAR_COUNT],
    SPOTLIGHT_BOH_DRIVE_THRU_SOS: [allFeatures.SPOTLIGHT_BOH_DRIVE_THRU_SOS],
    SPOTLIGHT_BOH_STORE_SPEED_OF_SERVICE: [allFeatures.SPOTLIGHT_BOH_STORE_SPEED_OF_SERVICE],
    SPOTLIGHT_BOH_HUDDLE_BOARD: [allFeatures.SPOTLIGHT_BOH_HUDDLE_BOARD],
    SPOTLIGHT_BOH_PRODUCT_SALES_LEADERBOARD: [allFeatures.SPOTLIGHT_BOH_PRODUCT_SALES_LEADERBOARD],
    SPOTLIGHT_BOH_SAME_STORE_SALES: [allFeatures.SPOTLIGHT_BOH_SAME_STORE_SALES],
    SPOTLIGHT_BOH_AVG_GUEST_SPEND: [allFeatures.SPOTLIGHT_BOH_AVG_GUEST_SPEND],
    SPOTLIGHT_BOH_PRODUCT_SALES_BY_STORE: [allFeatures.SPOTLIGHT_BOH_PRODUCT_SALES_BY_STORE],
    SPOTLIGHT_BOH_EMPLOYEE_LOYALTY_LEADERBOARD: [allFeatures.SPOTLIGHT_BOH_EMPLOYEE_LOYALTY_LEADERBOARD],
    SPOTLIGHT_BOH_TRAINING_BY_STORE: [allFeatures.SPOTLIGHT_BOH_TRAINING_BY_STORE],
    SPOTLIGHT_BOH_BIRTHDAY: [allFeatures.SPOTLIGHT_BOH_BIRTHDAY],
    SPOTLIGHT_BOH_ANNIVERSARY: [allFeatures.SPOTLIGHT_BOH_ANNIVERSARY],
    SPOTLIGHT_BOH_BIRTHDAY_SINGLE_EMPLOYEE: [allFeatures.SPOTLIGHT_BOH_BIRTHDAY_SINGLE_EMPLOYEE],
    SPOTLIGHT_BOH_ANNIVERSARY_SINGLE_EMPLOYEE: [allFeatures.SPOTLIGHT_BOH_ANNIVERSARY_SINGLE_EMPLOYEE],
    SPOTLIGHT_BOH_HUDDLE_NOTES: [allFeatures.SPOTLIGHT_BOH_HUDDLE_NOTES],
    BOH_SPOTLIGHTS: [allFeatures.BOH_SPOTLIGHTS],
    BOH_CONTESTS: [allFeatures.BOH_CONTESTS],
    LIVE_EVENTS: [allFeatures.LIVE_EVENTS],
    SPOTLIGHT_BOH_THERMOMETER_SALES: [allFeatures.SPOTLIGHT_BOH_THERMOMETER_SALES],
    SPOTLIGHT_BOH_PERCENTAGE_TO_GOAL: [allFeatures.SPOTLIGHT_BOH_PERCENTAGE_TO_GOAL],
    SPOTLIGHT_BOH_BIO: [allFeatures.SPOTLIGHT_BOH_BIO],
    SPOTLIGHT_BOH_EMPLOYEE_HIGHLIGHT: [allFeatures.SPOTLIGHT_BOH_EMPLOYEE_HIGHLIGHT],
    SPOTLIGHT_BLAZIN_BY_STORE: [allFeatures.SPOTLIGHT_BLAZIN_BY_STORE],
    SPOTLIGHT_BOH_BLAZIN_ATTACHMENT: [allFeatures.SPOTLIGHT_BOH_BLAZIN_ATTACHMENT],
    SPOTLIGHT_BOH_LOYALTY_GAUGE: [allFeatures.SPOTLIGHT_BOH_LOYALTY_GAUGE],
    SPOTLIGHT_BOH_PAYDAY: [allFeatures.SPOTLIGHT_BOH_PAYDAY],
    SPORTSBOOK: [allFeatures.SPORTSBOOK],
    SPORTSBOOK_SCHEDULE: [allFeatures.SPORTSBOOK_SCHEDULE],
    CAMPAIGNS: [allFeatures.CAMPAIGNS],
    CREATE_FROM_SIDEBAR: [allFeatures.CREATE_FROM_SIDEBAR],
    CAMPAIGN_CANVAS_DEFAULT_CREATOR: [allFeatures.CAMPAIGN_CANVAS_DEFAULT_CREATOR],
    OWN_CANVA_LOGIN: [allFeatures.OWN_CANVA_LOGIN],
    SPOTLIGHT_APPROVAL: [allFeatures.SPOTLIGHT_APPROVAL],
    BOH_LOYALTY_REPORT: [allFeatures.BOH_LOYALTY_REPORT],
    PREMIUM_MUSIC: [allFeatures.PREMIUM_MUSIC],
    MULTIPLE_DESTINATIONS_CAMPAIGN: [allFeatures.MULTIPLE_DESTINATIONS_CAMPAIGN],
    BILLING: [allFeatures.BILLING],
    SPOTLIGHT_BOH_SHOUTOUT: [allFeatures.SPOTLIGHT_BOH_SHOUTOUT],
    AUDIO_ANNOUNCEMENT: [allFeatures.AUDIO_ANNOUNCEMENT],
    SPOTLIGHT_BOH_PRODUCT_HIGHLIGHT: [allFeatures.SPOTLIGHT_BOH_PRODUCT_HIGHLIGHT],
    ORGANIZATION_LABELS: [allFeatures.ORGANIZATION_LABELS],
    SPOTLIGHT_LAYOUT_MULTI: [allFeatures.SPOTLIGHT_LAYOUT_MULTI],
    DR_TIME_BASED: [allFeatures.DR_TIME_BASED],
    SMART_PLAYLISTS: [allFeatures.SMART_PLAYLISTS],
    PEACOCK: [allFeatures.PEACOCK],
    BOH_EMPLOYEE_BULK: [allFeatures.BOH_EMPLOYEE_BULK],
    BLOCK_CONTENT: [allFeatures.BLOCK_CONTENT],
    SUNDAY_TICKET: [allFeatures.SUNDAY_TICKET],
    LIVE_EVENTS_MULTIPLE_DEVICES: [allFeatures.LIVE_EVENTS_MULTIPLE_DEVICES]
};

const hasFeature = (feature, enabled_features = []) => {
    return enabled_features.some(enabled_feature => functionalitySelector[feature]?.some(featureNameToCheck => enabled_feature === featureNameToCheck));
};

export const buildFeatureAccesor = (enabled_features = []) => {
    return (feature) => hasFeature(feature, enabled_features);
};
