import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { facebook as facebookApi } from '../../../api/modules';
import * as actions from './actions';

export function * getIgBusinessAccount (action) {
    const { organizationId, includeParents } = action.payload;

    try {
        const { error, response } = yield call(facebookApi.getIgBusinessAccounts, organizationId, includeParents);
        if (!!error) throw new Error(error);
        yield put(actions.getIgBusinessAccountsSuccess(organizationId, response));
    } catch (error) {
        const message = 'Couldn\'t get Instagram Business Account';
        yield call(toast.error, message);
        yield put(actions.getIgBusinessAccountsFailure(organizationId, { message, error }));
    }
}

export function * watchGetIgBusinessAccount () {
    yield takeEvery(actions.GET_IG_BUSINESS_ACCOUNTS, getIgBusinessAccount);
}

export function * disconnectIgBusinessAccount (action) {
    const { organizationId, pageId } = action.payload;

    try {
        const { error, successful, message } = yield call(facebookApi.disconnectIgBusinessAccount, organizationId, pageId);
        if (!!error) throw error;
        if (!successful) throw new Error(message);
        yield put(actions.disconnectIgBusinessAccountSuccess(organizationId, successful));
        yield put(actions.getIgBusinessAccounts(organizationId, false));
    } catch (error) {
        yield put(actions.disconnectIgBusinessAccountFailure(organizationId, error));
    }
}

export function * watchDisconnectIgBusinessAccount () {
    yield takeEvery(actions.DISCONNECT_IG_BUSINESS_ACCOUNT, disconnectIgBusinessAccount);
}

export default function * rootSaga () {
    yield all([
        watchGetIgBusinessAccount(),
        watchDisconnectIgBusinessAccount(),
    ]);
};