import {
    GET_IG_BUSINESS_ACCOUNTS,
    GET_IG_BUSINESS_ACCOUNTS_SUCCESS,
    GET_IG_BUSINESS_ACCOUNTS_FAILURE,
    DISCONNECT_IG_BUSINESS_ACCOUNT,
    DISCONNECT_IG_BUSINESS_ACCOUNT_SUCCESS,
    DISCONNECT_IG_BUSINESS_ACCOUNT_FAILURE,
} from "./actions";

const initialState = {
    isDisconnectingIgBusinessAccount: false,
    isGettingIgBusinessAccount: false,
};

export default function (state = initialState, action) {

    const {payload, type} = action;

    switch (type) {
        case GET_IG_BUSINESS_ACCOUNTS: {
            return {
                ...state,
                isGettingIgBusinessAccount: true,
            };
        }

        case GET_IG_BUSINESS_ACCOUNTS_SUCCESS: {
            const {organizationId, igAccounts} = payload;
            return {
                ...state,
                [organizationId]: {
                    igAccounts,
                },
                isGettingIgBusinessAccount: false,
            };
        }

        case GET_IG_BUSINESS_ACCOUNTS_FAILURE: {
            return {
                ...state,
                isGettingIgBusinessAccount: false,
            };
        }

        case DISCONNECT_IG_BUSINESS_ACCOUNT: {
            return {
                ...state,
                isDisconnectingIgBusinessAccount: true
            };
        }

        case DISCONNECT_IG_BUSINESS_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isDisconnectingIgBusinessAccount: false,
            };
        }

        case DISCONNECT_IG_BUSINESS_ACCOUNT_FAILURE: {
            return {
                ...state,
                isDisconnectingIgBusinessAccount: false
            };
        }

        default: {
            return state;
        }
    }
}