import {combineReducers} from "redux";

import entities from "./entities";
import facebook from './facebook';
import session from "./session";

export default combineReducers({
    entities,
    session,
    facebook
});