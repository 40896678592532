const NAMESPACE = 'facebook/';

export const GET_IG_BUSINESS_ACCOUNTS = `${NAMESPACE}GET_IG_BUSINESS_ACCOUNTS`;

export function getIgBusinessAccounts(organizationId, includeParents = true) {
    return {
        type: GET_IG_BUSINESS_ACCOUNTS,
        payload: {
            includeParents,
            organizationId,
        }
    };
}

export const GET_IG_BUSINESS_ACCOUNTS_SUCCESS = `${NAMESPACE}GET_IG_BUSINESS_ACCOUNTS_SUCCESS`;

export function getIgBusinessAccountsSuccess(organizationId, igAccounts) {
    return {
        type: GET_IG_BUSINESS_ACCOUNTS_SUCCESS,
        payload: {
            organizationId,
            igAccounts
        }
    };
}

export const GET_IG_BUSINESS_ACCOUNTS_FAILURE = `${NAMESPACE}GET_IG_BUSINESS_ACCOUNTS_FAILURE`;

export function getIgBusinessAccountsFailure(organizationId, error) {
    return {
        type: GET_IG_BUSINESS_ACCOUNTS_FAILURE,
        payload: {
            error,
            organizationId,
        },
    };
}

export const DISCONNECT_IG_BUSINESS_ACCOUNT = `${NAMESPACE}DISCONNECT_IG_BUSINESS_ACCOUNT`;

export function disconnectIgBusinessAccount(organizationId, pageId) {
    return {
        type: DISCONNECT_IG_BUSINESS_ACCOUNT,
        payload: {
            organizationId,
            pageId,
        }
    };
}

export const DISCONNECT_IG_BUSINESS_ACCOUNT_SUCCESS = `${NAMESPACE}DISCONNECT_IG_BUSINESS_ACCOUNT_SUCCESS`;

export function disconnectIgBusinessAccountSuccess(organizationId) {
    return {
        type: DISCONNECT_IG_BUSINESS_ACCOUNT_SUCCESS,
        payload: {
            organizationId,
        }
    };
}

export const DISCONNECT_IG_BUSINESS_ACCOUNT_FAILURE = `${NAMESPACE}DISCONNECT_IG_BUSINESS_ACCOUNT_FAILURE`;

export function disconnectIgBusinessAccountFailure(organizationId, error) {
    return {
        type: DISCONNECT_IG_BUSINESS_ACCOUNT_FAILURE,
        payload: {
            error,
            organizationId,
        },
    };
}